<template>
	<div :class="className" :style="{ width: width, height: height, margin: '0 auto', paddingRight: '2vw' }" />
</template>

<script>
	import echarts from "echarts";

	export default {
		props: {
			className: {
				type: String,
				default: "chart",
			},
			width: {
				type: String,
				default: "96%",
			},
			height: {
				type: String,
				// default: 'calc(100% - 30px)'
				default: "18vh",
			},
			autoResize: {
				type: Boolean,
				default: true,
			},
			sosPieData: {
				type: Array,
				default: () => [{
						value: 0,
						name: '维保'
					},
					{
						value: 0,
						name: '救援'
					},
					{
						value: 0,
						name: '理赔'
					},
					{
						value: 0,
						name: '维修'
					}
				],
			},
		},

		data() {
			return {
				chart: null,
			};
		},

		// 监听data里面对应的
		watch: {
			data: {
				deep: true,
				handler(val) {
					this.setOptions(val);
				},
			},
		},
		mounted() {
			this.$nextTick(() => {
				this.initChart();
			});
		},

		beforeDestroy() {
			if (!this.chart) return;
			// 释放图表实例
			this.chart.dispose();
			this.chart = null;
		},

		methods: {
			initChart() {
				this.chart = this.$echarts.init(this.$el);
				this.setOptions(this.sosPieData);
			},
			setOptions(data) {
				const options = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						orient: 'vertical',
						top: 'center',
						left: 'right',
						textStyle: {
							color: '#409eff' //字体颜色
						},
						data: ['维保', '救援', '理赔', '维修']
					},
					series: [{
						name: '平均用时',
						type: 'pie',
						avoidLabelOverlap: false,
						itemStyle: {
							borderColor: '#fff',
							borderWidth: 1
						},
						label: {
							show: false,
							position: 'center',
						},
						labelLine: {
							show: false
						},
						data: data
					}]
				}
				this.chart.setOption(options);
			},
		},
	};
</script>
