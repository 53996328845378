<template>
  <div class="admin-project-myproject-container">
    <div>
      <el-table ref="multipleTable" :data="InspectList" style="width: 100%; margin-bottom: 20px;" :height="$store.state.tabHeight-100">
        <el-table-column type="index" label="序号" min-width="55px" />
        <el-table-column label="计划名称" prop="detectionName" min-width="100px" />
        <el-table-column label="检验单位" prop="inspectionUnitName" min-width="100px" />
        <el-table-column label="检验日期" prop="detectionTime" min-width="100px" >
          <template #default="scope">
            {{$formatDate(new Date(scope.row.detectionTime),format = 'yyyy-MM-dd')}}
          </template>
        </el-table-column>
        <el-table-column label="检验类型" prop="typeName" min-width="100px" />
        <el-table-column label="操作" min-width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button type="primary" @click="check(scope.row,1)">查看</el-button>
            <el-button type="primary" @click="check(scope.row,2)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="Total" :page.sync="formInline.current" :limit.sync="formInline.size"
        @pagination="dictionarPage" />
    </div>
    <el-dialog :title="tit" v-if="dialogVisible" :visible.sync="dialogVisible" append-to-body
      :close-on-click-modal="false" width="950px" top="20vh">
      <div style="height: 40vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
        <el-form label-position="right" label-width="120px" ref="contractForm" :model="contractForm" :rules="formRules">
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="检验计划名称:" prop="name">
                <el-input class="wid90" v-model="contractForm.name" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择检验单位:" prop="orgId">
                <el-select v-if="!contractFormDisabled" v-model="contractForm.orgId" placeholder="请输入"
                  style="width: 100%" :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in orgName" :key="index" :label="item.name" :value="item.id" />
                </el-select>
                <el-input v-else class="wid90" v-model="contractForm.inspectionUnitName" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="检验时间:" prop="detectionTime">
                <el-date-picker v-model="contractForm.detectionTime" style="width: 100%" type="date" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="下次检验时间:" prop="nextTime">
                <el-date-picker v-model="contractForm.nextTime" style="width: 100%" type="date" placeholder="请输入"
                  :disabled="contractFormDisabled" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="12">
              <el-form-item label="检验类型:" prop="type">
                <el-select v-model="contractForm.type" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in typeDate.DETECTION_TYPE" :key="index" :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="检验结论:" prop="conclusion">
                <el-select v-model="contractForm.conclusion" placeholder="请输入" style="width: 100%"
                  :disabled="contractFormDisabled">
                  <el-option v-for="(item, index) in typeDate.DETECTION_RESULT" :key="index" :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin: 10px 0">
            <el-col :span="24">
              <el-form-item label="检验资料:">
                <div v-if="!contractFormDisabled">
                  <FileUploads
                    v-if="contractForm.fileUrl &&(contractForm.fileUrl.indexOf('.jpg') != -1 ||contractForm.fileUrl.indexOf('.png') != -1 ||contractForm.fileUrl.indexOf('.jpeg') != -1)"
                    :md5s.sync="contractForm.fileUrl" :multiple="false" :limit="1" :url="url" />
                  <FileUpload v-else :md5s.sync="contractForm.fileUrl" :multiple="false" :limit="1" :url="url" />
                </div>
                <template v-else>
                  <el-image v-if="
                      contractForm.fileUrl &&
                      (contractForm.fileUrl.indexOf('.jpg') != -1 ||
                        contractForm.fileUrl.indexOf('.png') != -1 ||
                        contractForm.fileUrl.indexOf('.jpeg') != -1)
                    " style="width: 150px; height: 150px" :src="contractForm.fileUrl" :fit="'contain'" />
                  <span v-else @click="openFile(contractForm.fileUrl )">{{ contractForm.fileUrl }}</span>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" v-if="!contractFormDisabled" type="primary" :loading="loading" @click="dioSub">确
          定</el-button>
        <el-button size="small" v-if="!contractFormDisabled" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import FileUpload from '../../../../components/FileUploadPWP.vue'
  import FileUploads from '../../../../components/FileUpload.vue'
  import {
    formatDate
  } from "@/util";
  export default {
    components: {
      Pagination,
      FileUpload,
      FileUploads
    },
    props: {
      elevatorId: {
        type: String,
        default: '',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        InspectList: [],
        typeDate: [],
        boxshow: false,
        formInline: {
          current: 1,
          size: 10,
        },
        Total: 0,
        tit: '',
        loading: false,
        url: '/api/file/web/uploadByOSS', // 上传地址
        // 检验单位
        orgName: [],
        dialogVisible: false,
        contractFormDisabled: false,
        contractForm: {},
        formRules: {
          elevator_name: [{
            required: true,
            message: '电梯名称不能为空',
            trigger: 'change'
          }, ],
          name: [{
            required: true,
            message: '计划名称不能为空',
            trigger: 'blur'
          }, ],
          orgId: [{
            required: true,
            message: '检验单位名称不能为空',
            trigger: 'blur'
          }, ],
          type: [{
            required: true,
            message: '检验类型不能为空',
            trigger: 'blur'
          }, ],
          detectionTime: [{
            required: true,
            message: '检验时间不能为空',
            trigger: 'blur'
          }, ],
          nextTime: [{
            required: true,
            message: '下次检验时间不能为空',
            trigger: 'blur'
          }, ],
          conclusion: [{
            required: true,
            message: '检验结论不能为空',
            trigger: 'blur'
          }, ],
          fileUrl: [{
            required: true,
            message: '检验资料不能为空',
            trigger: 'blur'
          }, ],
        },
      }
    },
    mounted() {
      this.getInList() //电梯检验列表
      this.getOrg() //获取检验单位
    },
    created() {
      this.dectionary(['DETECTION_TYPE', 'DETECTION_TYPE', 'DETECTION_RESULT'])
    },
    methods: {
      getOrg() {
        const data = {
          current: 1,
          size: 999,
          type: 'CHECK_UNIT',
        }
        this.$http.post('/api/system/web/org/list', data).then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res
            this.orgName = data.records ?? []
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      dectionary(code) {
        this.$http.post(`api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            this.typeDate = res.data.data
          })
      },
      getTName(typeDate, obj) {
        let tn = []
        typeDate.forEach(qs => {
          if (qs.value == obj) {
            tn.push(qs.label)
            return
          }
        })
        return tn.join(',')
      },
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.getInList()
      },
      getInList() { //电梯检验数据
        const data = {
          current: this.formInline.current,
          size: this.formInline.size,
          elevatorId: this.elevatorId
        }
        this.$http.post('/api/ele/web/eleDetection/getEleDetectionList', data)
          .then((res) => {
            if (res.data.code == 200) {
              res.data.data.records.forEach((qs) => {
                qs.typeName = this.getTName(this.typeDate.DETECTION_TYPE, qs.type)
              })
              this.InspectList = res.data.data.records ?? []
              this.Total = res.data.data.total ?? 0
              this.$nextTick(() => {
                if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                  this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                }
              })
            }
          })
      },
      //提交确认
      dioSub() {
        this.loading = true
        this.$refs.contractForm.validate(async (valid) => {
          if (valid) {
            if (new Date(this.contractForm.detectionTime).getTime() > new Date(this.contractForm.nextTime).getTime()) {
              this.$message.error('下次检验时间必须晚于检验时间')
              return
            }
            const data = JSON.parse(JSON.stringify(this.contractForm))
            data.detectionTime = new Date(
              new Date(Date.parse(this.contractForm.detectionTime)).getTime() +
              8 * 60 * 60 * 1000
            )
            data.nextTime = new Date(
              new Date(Date.parse(this.contractForm.nextTime)).getTime() +
              8 * 60 * 60 * 1000
            )
            data.inspectionUnitName = this.orgName.find(item => item.id == this.contractForm.orgId).name
            const from = {
                  detectionId: data.id,
                  name: data.name,
                  orgId: data.orgId,
                  detectionTime: data.detectionTime,
                  nextTime: data.nextTime ,
                  type: data.type,
                  conclusion: data.conclusion,
                  fileUrl: data.fileUrl,
                  inspectionUnitName: data.inspectionUnitName
            }
            //编辑
            this.$http.post('/api/ele/web/detection/updateDetection', from).then((res) => {
              if (res.data.success) {
                this.getInList()
                this.dialogVisible = false
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        })
        this.loading = false
      },
      openFile(url) {
        window.open(url, '_blank');
      },
      //查看 or 编辑
      check(item, type) {
        this.dialogVisible = true
        if (type == 1) {
          this.tit = '电梯检验详情'
          this.contractFormDisabled = true
        } else {
          this.tit = '编辑电梯检验'
          this.contractFormDisabled = false
        }
        this.$http
          .get(`/api/ele/web/detection/detail?id=${item.detectionId}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res

              this.contractForm = JSON.parse(JSON.stringify(data))
              this.contractForm.detectionId = item.id

            } else {
              this.$message.error(res.data.msg)
            }
          })
      },
    }
  }
</script>

<style lang="scss" scoped>

</style>
