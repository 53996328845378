<template>
  <el-dialog v-if="visiableStock" title="未收到款项" :visible.sync="visiableStock" append-to-body
    :close-on-click-modal="false" width="1100px" @close="back">
    <div style="padding: 10px;margin-left: 20%;">
      <el-form label-position="left" ref="formList" :model="formList" :rules="rules">
        <el-form-item label="不通过原因" prop="reason" label-width="100px">
          <el-select v-model="formList.reason" style="width: 69.5%;" @change="Checkreason" clearable>
            <el-option v-for="(item,index) in reasonList" :key="index" :value="item.label"
              :label="item.label"></el-option>
          </el-select>
          <!-- <el-input style="width: 60%;" v-model="formList.reason"></el-input> -->
        </el-form-item>
        <el-form-item prop="reasonInfo"
          :rules="formList.reason == '其他原因' ?{ required: true, message:'请输入其他原因', trigger: 'blur'} :[]">
          <div style="display: flex;flex-direction: column;">
            <span slot="label">其他原因,请补充</span>
            <el-input type="textarea" maxlength="50" style="width: 73.7%;" :disabled="formList.reason != '其他原因'"
              placeholder="请输入,不超过50字" v-model="reasonInfo"></el-input>
          </div>
        </el-form-item>
        <div>
          <el-button type="text" @click="chackInfo">短信验证</el-button>
          <el-button type="text" @click="checkPass">登录密码验证</el-button>
          <div v-if="isShow">
            <el-form-item prop="code">
              <div style="display: flex;flex-direction: column;">
                <span slot="label">手机号：{{adminInfo.phone}}</span>
                <el-input style="width:73.7%;" placeholder="请输入短信验证码" v-model="formList.code">
                  <template slot="append">
                    <el-button @click="send" :disabled="(!timeShow )"
                      type="primary">{{ timeShow ? '发送验证码' : `${time}秒后发送` }}</el-button>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </div>
          <div v-if="!isShow">
            <el-form-item prop="password">
              <el-input style="width:73.7%;" placeholder="请输入登录密码" v-model="formList.password"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="srue">确认</el-button>
      <el-button @click="back">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: ['Id'],
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        visiableStock: true,
        isShow: true,
        time: '',
        timeShow: true,
        timershow: null,
        reasonList: [{
            value: 0,
            label: '超出规定时间内未按要求转账，系统自动取消，支付失败'
          },
          {
            value: 1,
            label: '实际未收到款项'
          },
          {
            value: 2,
            label: '金额不对'
          },
          {
            value: 3,
            label: '其他原因'
          }
        ],
        reasonInfo: '',
        formList: {
          id: '',
          auditType: 1,
          code: '',
          password: '',
          reason: '',
        },
        rules: {
          reason: [{
            required: true,
            message: '请选择不通过原因',
            trigger: 'change',
          }, ],
          code: [{
            required: true,
            message: '请输入短信验证码',
            trigger: 'blur',
          }, ],
          password: [{
            required: true,
            message: '请输入登录密码',
            trigger: 'blur',
          }, ],
          // reasonInfo:[{
          //   required: true,
          //   message:'请输入其他原因',
          //   trigger: 'blur',
          // }, ],
        },
      }
    },
    created() {
      // this.visiableStock = true
      let EndTime = sessionStorage.getItem('ontReceuveEndTime');
      if (EndTime) {
        this.downTime(EndTime);
      }
      // const { time , resumeCountDown } = startCountDown(60)
    },
    methods: {
      Checkreason(val) {
        console.log(val, 'val')
        if (val == '其他原因') {
          this.reasonInfo = ''
        } else {
          this.reasonInfo = this.formList.reason
        }
      },
      back() {
        this.$emit('close')
        this.visiableStock = false
      },
      srue() {
        if (this.reasonInfo) {
          this.formList.reason = this.reasonInfo
        }
        this.formList.id = this.Id
        this.$refs.formList.validate((valid) => {
          if (valid) {
            this.$http.post('/api/system/web/recharge/auditOutstandingCollect', this.formList).then(res => {
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                })
                this.visiableStock= false
                this.$emit('sureStock')
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }

            })
          }
        })
      },
      chackInfo() {
        this.isShow = true
        this.formList.auditType = 1
        this.$refs['formList'].clearValidate()
      },
      checkPass() {
        this.isShow = false
        this.formList.auditType = 2
        this.$refs['formList'].clearValidate()
      },
      downTime(timer) {
        if (timer) {
          this.time = Math.ceil((JSON.parse(timer) - new Date().getTime()) / 1000);
          this.timeShow = false;
          this.timershow = setInterval(() => {
            if (this.time > 0) {
              this.time--
            } else {
              this.timeShow = true;
              clearTimeout(this.timershow)
              sessionStorage.removeItem('ontReceuveEndTime')
              return
            }
          }, 1000)
        }
      },
      send() {
        let clicktime = new Date().getTime() + 60000;
        sessionStorage.setItem('ontReceuveEndTime', JSON.stringify(clicktime));
        console.log(clicktime, 'clicktime')
        // this.data.count = Math.ceil((JSON.parse(time) - new Date().getTime())/1000);  //取出计时
        // if (this.time == 0) {
        // this.time = 60
        this.$http.get(`/api/system/web/sms/sendTransferSms?phone=${this.adminInfo.phone}`).then(res => {
          
          if (res.data.code == 200) {
            this.downTime(clicktime)
            this.$message({
              type: "success",
              message: res.data.msg
            })
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            })
          }
        })

        // }


      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.el-textarea__inner {
    height: 100px;
  }
</style>
