<template>
  <el-dialog v-if="visiableStock" title="确认收到款项" :visible.sync="visiableStock" append-to-body
    :close-on-click-modal="false" width="1100px" @close="back">
    <div style="width: 58%;margin: 20px 18%;">
      <div style="display: flex;align-items: center;margin-bottom: 30px;">
        <!-- <el-image src="https://test-mallshop.oss-cn-shenzhen.aliyuncs.com/mall/images/2024-01/11/202401111511062509.png"
          style="width: 30px;margin-right: 20px;" fit="cover" /> -->
        <i class="el-icon-warning-outline icons_size" style="margin-right: 30px;"></i>
        <span>请务必在确认收到对应金额的款项后，进行操作。以免造成资金损失。</span>
      </div>
      <el-form ref="formList" :model="formList" :rules="rules" label-position="left">
        <el-form-item label="付款户名" prop="payAccount">
          <el-input placeholder="请输入付款户名" style="width: 84%;" v-model="formList.payAccount"></el-input>
        </el-form-item>
        <el-form-item label="付款卡号" prop="payCard">
          <el-input placeholder="请输入付款卡号" style="width: 84%;" v-model.trim="formList.payCard"></el-input>
        </el-form-item>
        <el-form-item label="付款银行" prop="payBank">
          <el-input placeholder="请输入付款银行" style="width: 84%;" v-model="formList.payBank"></el-input>
        </el-form-item>
        <el-form-item label="转账单号/交易流水号" prop="payNumber" label-width="160px">
          <el-input placeholder="请输入转账单号" style="width: 96.5%;" v-model.trim="formList.payNumber"></el-input>
        </el-form-item>
        <div>
          <el-button type="text" @click="chackInfo">短信验证</el-button>
          <el-button type="text" @click="checkPass">登录密码验证</el-button>
          <div v-if="isShow">
            <el-form-item prop="code">
              <div style="display: flex;flex-direction: column;">
                <span slot="label">手机号：{{adminInfo.phone}}</span>
                <el-input style="width:99.5%;" placeholder="请输入短信验证码" v-model="formList.code">
                  <template slot="append">
                    <el-button @click="send" :disabled="(!timeShow )"
                      type="primary">{{ timeShow ? '发送验证码' : `${time}秒后发送` }}</el-button>
                  </template>
                </el-input>
              </div>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item prop="password">
              <el-input style="width:99.5%;" placeholder="请输入登录密码" v-model="formList.password"></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="srue">确认</el-button>
      <el-button @click="back">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    props: ['Id'],
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        visiableStock: true,
        isShow: true,
        time: '',
        timeShow: true,
        timeshow: null,
        formList: {
          id: '',
          payAccount: '',
          payCard: '',
          payBank: '',
          payNumber: '',
          auditType: 1,
          code: '',
          password: '',
        },
        rules: {
          payAccount: [{
            required: true,
            message: '请输入付款户名',
            trigger: 'blur',
          }],
          payCard: [{
            required: true,
            message: '请输入付款卡号',
            trigger: 'blur',
          }],
          payBank: [{
            required: true,
            message: '请输入付款银行',
            trigger: 'blur',
          }],
          payNumber: [{
            required: true,
            message: '请输入转账单号',
            trigger: 'blur',
          }],
          code: [{
            required: true ,
            message: '请输入短信验证码',
            trigger: 'blur',
          }],
          password: [{
            required: true,
            message: '请输入登录密码',
            trigger: 'blur',
          }]
        }
      }
    },
    created() {
      this.getDate()
      // this.visiableStock = true
      let EndTime = sessionStorage.getItem('ReceuveEndTime');
      if (EndTime) {
        this.downTime(EndTime);
      }
    },
    methods: {
      getDate() {
        const data = {
          size: 1,
          current: 1,
          id: this.Id ? this.Id : ""
        }
        this.$http.post('/api/system/web/recharge/getRechargeRecordReviewList', data).then(res => {
          
          if (res.data.code == 200) {
            const data = res.data.data.records[0]
            this.formList = {
              id: '',
              payAccount: data.payAccount,
              payCard: data.payCard,
              payBank: data.payBank,
              payNumber: data.payNumber,
              auditType: 1,
              code: '',
              password: '',
            }
          }


          // if (res.data.code == 200) {
          //   this.tableDate = res.data.data.records
          //   this.dictionarTotal = res.data.data.total
          //   this.$nextTick(() => {
          //     if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
          //       this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
          //       this.$refs.multipleTable.doLayout();
          //     }
          //   })
          // }
        })
      },
      chackInfo() {
        this.isShow = true
        this.formList.auditType = 1
        this.$refs['formList'].clearValidate()
      },
      checkPass() {
        this.isShow = false
        this.formList.auditType = 2
        this.$refs['formList'].clearValidate()
      },
      //60秒倒计时
      downTime(timer) {
        if (timer) {
          this.time = Math.ceil((JSON.parse(timer) - new Date().getTime()) / 1000);
          this.timeShow = false;
          this.timershow = setInterval(() => {
            if (this.time > 0) {
              this.time--
            } else {
              this.timeShow = true;
              clearTimeout(this.timershow)
              sessionStorage.removeItem('ReceuveEndTime')
              return
            }
          }, 1000)
        }
      },
      //短信验证码
      send() {
        let clicktime = new Date().getTime() + 60000;
        sessionStorage.setItem('ReceuveEndTime', JSON.stringify(clicktime));
        console.log(clicktime, 'clicktime')
        // this.data.count = Math.ceil((JSON.parse(time) - new Date().getTime())/1000);  //取出计时
        // if (this.time == 0) {
        // this.time = 60
        this.$http.get(`/api/system/web/sms/sendTransferSms?phone=${this.adminInfo.phone}`).then(res => {
          
          if (res.data.code == 200) {
            this.downTime(clicktime)
            this.$message({
              type: "success",
              message: res.data.msg
            })
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            })
          }
        })

        // }


      },
      //取消
      back() {
        this.$emit('close')
        this.visiableStock = false
      },
      //确认
      srue() {
        console.log(this.formList, 111)
        this.formList.id = this.Id
        console.log(this.formList, 222)
        this.$refs.formList.validate((valid) => {
          if (valid) {
            this.$http.post('/api/system/web/recharge/auditAndCollect', this.formList).then(res => {
              
              if (res.data.code == 200) {
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.$emit('sureStock')
                this.visiableStock = false
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
          }
        })

      },
    }
  }
</script>

<style lang="scss" scoped>
</style>
