<template>
  <div class="main">
    <el-row :gutter="15">
      <el-col :span="18">
        <!-- 顶部 -->
        <div>
          <el-row :gutter="15">
            <el-col :span="6">
              <div class="boxShow">
                <div class="dataBoxView">
                  <div class="dataBoxRight">
                    <h2>{{elevator.elevatorCount ? elevator.elevatorCount : 0}}</h2>
                    <p>电梯总数</p>
                  </div>
                  <!-- 折线图 -->
                  <div style="margin-top: 5px;">
                    <img src="../../assets/linechart/1.png" alt="">
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="boxShow">
                <div class="dataBoxView">
                  <div class="dataBoxRight">
                    <h2>{{elevator.underWarrantyElevatorCount ? elevator.underWarrantyElevatorCount : 0}}</h2>
                    <p>在保电梯</p>
                  </div>
                  <!-- 折线图 -->
                  <div style="margin-top: 5px;">
                    <img src="../../assets/linechart/2.png" alt="">
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="boxShow">
                <div class="dataBoxView">
                  <div class="dataBoxRight">
                    <h2>{{elevator.netElevatorCount ? elevator.netElevatorCount : 0}}</h2>
                    <p>入网电梯</p>
                  </div>
                  <!-- 折线图 -->
                  <div style="margin-top: 5px;">
                    <img src="../../assets/linechart/3.png" alt="">
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="boxShow">
                <div class="dataBoxView">
                  <div class="dataBoxRight">
                    <h2>{{elevator.offNetElevatorCount ? elevator.offNetElevatorCount : 0}}</h2>
                    <p>未入网电梯</p>
                  </div>
                  <!-- 折线图 -->
                  <div style="margin-top: 5px;">
                    <img src="../../assets/linechart/4.png" alt="">
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 中部 -->
        <div>
          <el-row :gutter="15">
            <el-col :span="12">
              <div class="boxShow2">
                <div class="charsLeft">
                  <h3>电梯实时状态</h3>
                  <StatusChart v-if="eleStatusType" :eleStatus="eleStatus" :height="'25vh'"
                    style="margin-top: -20px;" />
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="boxShow2">
                <div class="charsRight">
                  <template v-if="Object.keys('notice').lenght !== 0">
                    <h3>{{ notice.title }}</h3>
                    <h5>{{ notice.updateTime }}</h5>
                    <div class="noticeBox">
                      <div class="noticeDetail scrollElement">
                        <quill-editor @focus="focus($event)" @change="onChange($event)" v-model="notice.detail"
                          :options="editorOption" class="quillClass" />
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <h3>暂无公告</h3>
                  </template>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <!-- 尾部 -->
        <div>
          <el-row>
            <el-col :span="24">
              <div class="boxShow3">
                <div class='mouthPlan'>
                  <div style="display: flex;justify-content: space-between;">
                    <h3>本月保养计划一览</h3>
                    <div calss="block">
                      <el-date-picker v-model="Datevalue" type="month" placeholder="选择月" @change="changeDate()"
                        style="width: 200px;">
                      </el-date-picker>
                    </div>
                    <!-- </div> -->
                  </div>
                  <Maintenance v-if="eleMainType" :eleMain="eleMain" :height="'29vh'" :width="'100%'" />
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="6">
        <!-- 今日维保 -->
        <el-row>
          <el-col :span="24">
            <div class="boxShow4">
              <div class="infoBox">
                <div class="box-content">
                  <h3>今日维保</h3>
                  <div style="display: flex;overflow: hidden; white-space: nowrap;text-overflow: ellipsis;width:400px">
                    <div class="newBox">
                      <p class="boxcolor1" />已维保
                    </div>
                    <div class="newBox">
                      <p class="boxcolor2" />未维保
                    </div>
                    <div class="newBox">
                      <p class="boxcolor3" />计划维保
                    </div>
                  </div>
                </div>
                <div class="Maincontent" v-if="mainStatus" :key="keyShow">
                  <div class="mainInfo1" :style="{width:mianed}">
                    <span class="mainPlan">{{mianform.Maintained}}</span>
                  </div>
                  <div class="mainInfo2" :style="{width:mians}">
                    <span class="mainPlan">{{mianform.Unmaintained}}</span>
                  </div>
                  <div class="mainInfo3" :style="{width: All}">
                    <span
                      class="mainPlan">{{ mianform.Unmaintained || mianform.Maintained? mianform.Maintenanceplan : 0}}</span>
                  </div>
                </div>
                <div style="background-color: #cccccc;height: 5vh;width:100%;" v-else></div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 今日报警 -->
        <el-row>
          <el-col :span="24">
            <div class="boxShow5">
              <div class="infoBox2">
                <div class="box-content">
                  <h3>电梯报警top 5</h3>
                  <div style="display: flex;">
                    <span style="cursor: pointer;" :class="{'poin':fos1 == true}" @click="changePloce(1)"
                      title="昨日">日</span>
                    <span style="margin-left: 15px;cursor: pointer;" :class="{'poin':fos2 == true}"
                      @click="changePloce(2)" title="前七天">周</span>
                    <span style="margin-left: 15px;cursor: pointer;" :class="{'poin':fos3 == true}"
                      @click="changePloce(3)" title="前三十天">月</span>
                  </div>
                </div>
                <div class="elecount">
                  <ol>
                    <li v-for="(item,index) in SosList">
                      <div style="display: flex;justify-content: space-between;font-size: 1.4vh;">
                        <p class="pageBox" :title="item !=undefined ? item.eleName : ''">
                          {{item !=undefined ? item.eleName : ''}}
                        </p>
                        <div>({{(eleSos[index])}})</div>
                      </div>
                    </li>
                  </ol>
                  <!-- 饼状图 -->
                  <div style="margin-top: -15px;">
                    <SosHome v-if="SosHomeType" :eleSos="eleSos" :height="'17vh'" :width="'17vh'" />
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 本月维保 -->
        <el-row>
          <el-col :span="24">
            <div class="boxShow5">
              <div class="infoBox2">
                <div class="box-content">
                  <h3>本月维保任务完成top 5</h3>
                  <span>维保次数</span>
                </div>
                <div class="elecount">
                  <ol style="width:100%;">
                    <li v-for="(item,index) in Maintain" v-if="item">
                      <div style="display: flex;justify-content: space-between;font-size: 1.4vh;">
                        <p class="pageBox" :title="item ? item.name : ''">{{item ? item.name : ''}}</p>
                        <p v-if="item">{{item.taskNum}}</p>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- 电梯总数 -->
        <el-row>
          <el-col :span="24">
            <div class="boxShow5">
              <div class="infoBox2">
                <div class="box-content">
                  <h3>物业单位电梯总数top 5</h3>
                  <span>电梯台数</span>
                </div>
                <div class="elecount">
                  <ol style="width:100%;">
                    <li v-for="(item,index) in countList" v-if="item">
                      <div style="display: flex;justify-content: space-between;font-size: 1.4vh;">
                        <p class="pageBox" :title="item ? item.name : ''">{{item ? item.name : ''}}</p>
                        <p v-if="item">{{item.eleNum }}</p>
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import StatusChart from "./components/statusChart";
  import Maintenance from './components/Maintenance .vue';
  import SosHome from './components/sosHome.vue'
  // import SosHome from './'
  import {
    formatDate
  } from "@/util";

  let current_time = formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00";
  let current_time1 = formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59";
  // let current_time1 = new Date(current_time).getTime();
  // let sosStarTime = new Date(current_time1 - 2 * 24 * 60 * 60 * 1000).getTime();
  // let sosEndTime = new Date(current_time1 + 24 * 60 * 60 * 1000 - 1000).getTime();
  export default {
    components: {
      StatusChart,
      Maintenance,
      SosHome
    },
    data() {
      return {
        notice: {},
        // topData: {
        //   DayCount: 0, // 需维保
        //   DayFinishedCount: 0, // 未维保
        //   DayUnfinishedCount: 0, // 已维保
        //   inspectionCount: 0, // 已超期
        // },
        eleStatus: {
          xAxis: ["在线", "维保中", "故障", "困人", "离线"], // x轴
          seriesData: [0, 0, 0, 0, 0], // 数据
        },
        eleMain: { //维保柱状图
          xAxis: [], // x轴
          seriesData: [0, 0, 0, 0, 0], // 数据
        },
        eleSos: [],
        mianform: {},
        mianed: 0,
        mians: 0,
        All: 0,
        mainStatus: false, //统计
        eleMainType: false, //维保柱状图
        SosHomeType: false, //电梯报警饼状图
        eleStatusType: false,
        Datevalue: '',
        // 富文本编辑器
        editorOption: {
          modules: {
            toolbar: [
              ["bold"], // toggled buttons

              [{
                size: ["small", false, "large", "huge"]
              }], // custom dropdown
            ],
          },
        },
        keyShow: 1,
        elevator: {},
        countList: [],
        Maintain: [],
        SosList: [],
        fos1: false,
        fos2: false,
        fos3: false,
      };
    },
    created() {
      this.countList = []
      this.Maintain = []
      this.SosList = []
      this.eleSos = []
      this.getCountDays()
      this.getHomeData()
      this.getPropertyTop()
      let tim = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      )
      const dateStr = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).getTime()
      const dateEnd = new Date(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1) - 1000
      ).getTime()
      this.getmainList([dateStr, dateEnd])
      this.getMaintainTop([dateStr, dateEnd])
      this.getMain([current_time, current_time1]);
      this.Datevalue = dateStr
      this.$http
        .post("/api/system/web/dict/dictionary?codes=SOS_TYPE")
        .then((res) => {
          if (res.data.success) {
            this.typeDate = res.data.data;
            this.getTopData();
            this.getNotice();
          }
        });
    },
    mounted() {
      this.changePloce(1)
    },
    methods: {
      //电梯报警数据top5
      changePloce(type) {
        this.SosHomeType = false
        this.SosList = []
        this.eleSos = []
        const data = {
          startTime: '',
          endTime: ''
        }
        if (type == 1) { //日
          this.fos1 = true
          this.fos2 = false
          this.fos3 = false
          let start = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00").getTime();
          data.startTime = new Date(start - 1 * 24 * 3600 * 1000).getTime();
          let end = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime();
          data.endTime = new Date(end - 1 * 24 * 3600 * 1000).getTime();
        } else if (type == 2) { //周
          this.fos2 = true
          this.fos1 = false
          this.fos3 = false
          // let end = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime();
          // data.endTime = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime();
          let end = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime();
          data.endTime = new Date(end - 1 * 24 * 3600 * 1000).getTime();
          let start = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00").getTime();
          data.startTime = new Date(start - 7 * 24 * 3600 * 1000).getTime();
          // data.startTime = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay(), 1).getTime();
          // let now = new Date(new Date())
          // let nowDayOfWeek = now.getDay(); // 今天本周的第几天
          // let day = nowDayOfWeek || 7;
          // let nowDay = now.getDate(); // 当前日
          // let nowMonth = now.getMonth(); // 当前月
          // data.startTime = new Date(formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 1 - day))).getTime()
        } else { //月
          this.fos3 = true
          this.fos1 = false
          this.fos2 = false
          let end = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 23:59:59").getTime();
          data.endTime = new Date(end - 1 * 24 * 3600 * 1000).getTime();
          let start = new Date(formatDate(new Date(), "yyyy-MM-dd") + " 00:00:00").getTime();
          data.startTime = new Date(start - 30 * 24 * 3600 * 1000).getTime();
        }
        this.$http.post('/api/ele/web/sos/getHomeSosTop', data).then((res) => {
          this.SosHomeType = true
          if (res.data.code) {
            for (var i = 0; i < 5; i++) {
              // this.SosList.push(res.data.data[i])
              if (res.data.data[i] != undefined) {
                this.SosList.push(res.data.data[i])
                this.eleSos.push(JSON.parse(res.data.data[i].sosCount))
              }
            }
          }
        })
      },
      //维保单位电梯数top5
      getMaintainTop(Arr) {
        const data = {
          startTime: Arr[0],
          endTime: Arr[1]
        }
        this.$http.post('/api/system/web/org/getMaintainOrgListByCount', data).then((res) => {
          if (res.data.code) {
            for (var i = 0; i < 5; i++) {
              this.Maintain.push(res.data.data[i])
            }
          }
        })
      },
      //物业单位电梯数top5
      getPropertyTop() {
        this.$http.get('/api/system/web/org/getPropertyOrgListByCount').then((res) => {
          if (res.data.code) {
            for (var i = 0; i < 5; i++) {
              this.countList.push(res.data.data[i])
            }
          }
        })
      },
      //电梯数据
      getHomeData() {
        this.$http.get(`/api/ele/web//elevatorInfo/getHomeElevatorData`).then((res) => {
          if (res.data.code == 200) {
            this.elevator = res.data.data
          }
        })
      },
      getCountDays() {
        var now = this.Datevalue ? this.Datevalue : new Date(); // 当前日期
        var nowMonth = now.getMonth(); // 当前月
        var nowYear = now.getYear(); // 当前年
        nowYear += nowYear < 2000 ? 1900 : 0;
        // 本月天数
        var monthStartDate = new Date(nowYear, nowMonth, 1);
        var monthEndDate = new Date(nowYear, nowMonth + 1, 1);
        var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
        // 本月结束时间
        var monthEndDate = new Date(nowYear, nowMonth, days);
        let count = formatDate(monthEndDate);
        let nums = formatDate(new Date(count), 'dd')
        for (var i = 1; i <= nums; i++) {
          this.eleMain.xAxis.push(i)
        }
      },
      //获取焦点事件
      focus(event) {
        event.enable(false) //设置富文本编辑器不可编辑
      },
      onChange(event) {
        event.quill.enable(false) //设置富文本编辑器不可编辑
      },
      getmainList(time) {
        this.eleMainType = false
        const data = {
          searchStartTime: time
        }
        this.$http.post("/api/ele/web/maintainTask/getCalendar", data).then((res) => {
          this.eleMainType = true
          if (res.data.code == 200) {
            this.eleMain.seriesData = res.data.data
          }
        })
      },
      changeDate(type) {
        this.eleMain.xAxis = []
        const year = this.Datevalue.getFullYear()
        const month =
          this.Datevalue.getMonth() + 1 > 9 ?
          this.Datevalue.getMonth() + 1 :
          `0${this.Datevalue.getMonth() + 1}`
        const day =
          new Date().getDate() > 9 ?
          new Date().getDate() :
          `0${new Date().getDate()}`
        const resDate = `${year}-${month}-${day}`
        const dateStr = new Date(year, month - 1, 1).getTime()
        const dateEnd = new Date(year, month, 1) - 1000
        this.getmainList([dateStr, dateEnd])
        this.getCountDays()
        // this.getMain([dateStr, dateEnd])
        // this.getCalendar([dateStr, dateEnd])
      },

      getMain(time) {
        const data = {
          searchStartTime: time
        }
        this.$http.post("/api/ele/web/maintainTask/getCount", data).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data) {
              // this.mainStatus = res.data.data.ywc == undefined || res.data.data.wwb == undefined ? false : true;
              this.mainStatus = res.data.data.ywc || res.data.data.wwb ? true : false;
              this.mianform = {
                Maintained: res.data.data.ywc,
                Unmaintained: res.data.data.wwb,
                Maintenanceplan: res.data.data.ywc + res.data.data.wwb,
                nums: (res.data.data.ywc + res.data.data.wwb) + res.data.data.wwb + res.data.data.ywc, //条总长
              }
              this.mianed = Math.ceil((this.mianform.Maintained / this.mianform.nums) * 100) > 10 ? Math.ceil((this
                .mianform.Maintained / this.mianform.nums) * 100) + "%" : 10 + '%'
              this.mians = Math.ceil((this.mianform.Unmaintained / this.mianform.nums) * 100) > 10 ? Math.ceil((this
                .mianform.Unmaintained / this.mianform.nums) * 100) + '%' : 10 + '%'
              this.All = Math.ceil((this.mianform.Maintained / this.mianform.nums) * 100) > 10 || Math.ceil((this
                .mianform.Unmaintained / this.mianform.nums) * 100) > 10 ? Math.ceil((
                this.mianform.Maintenanceplan / this.mianform.nums) * 100) + '%' : 10 + '%'
              this.keyShow++
              this.$forceUpdate()
            }
          }
        })
      },
      getTopData() {
        this.$http.get("/api/ele/web/maintainTask/loginTopData").then((res) => {
          if (res.data.success) {
            const {
              data: {
                data
              },
            } = res;

            this.eleStatusType = true;
            if (data.eleData) {
              this.eleStatus.seriesData = [
                data.eleData.onlineNum,
                data.eleData.maintainCount,
                data.eleData.failureNum,
                data.eleData.rescueNum,
                data.eleData.unOlineNum,
              ];
            }
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      getNotice() {
        this.$http
          .get("/api/system/web/announcement/loginGetAnnouncement")
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res;
              this.notice = data;
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .main {
    height: 80vh;
  }

  // .boxShow {
  //   margin: 0px 0px 15px 0px;
  //   height: 10vh;
  //   border-radius: 8px;
  //   color: #fff;
  //   overflow: hidden;
  //   border: 1px solid #00f6ff;
  //   background: #23649387;
  //   cursor: pointer;
  //   box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
  // }

  .dataBoxView {
    // border:1px solid pink;
    margin: -5px 10px 5px 10px;
    display: flex;
    justify-content: space-between;

    .dataBoxRight {
      font-size: 1.6vh;
    }
  }

  // .boxShow2 {
  //   margin: 0px 0px 15px 0px;
  //   height: 32vh;
  //   border-radius: 8px;
  //   color: #fff;
  //   overflow: hidden;
  //   border: 1px solid #00f6ff;
  //   background: #23649387;
  //   cursor: pointer;
  //   box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
  // }

  .charsLeft {
    margin: 0px 20px 0px 20px;
    height: 100%
  }

  .charsRight {
    width: 93.5%;
    height: 30vh;
    margin: 0px 20px 0px 20px;

    h3 {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      /*文字超出宽度则显示ellipsis省略号*/
      text-overflow: ellipsis;
    }

    h5 {
      margin: 5px 0;
    }

    .noticeBox {
      min-height: 20vh;

      .noticeDetail {
        height: 18vh;
        line-height: 30px;
        font-size: 1.6vh;
        overflow-y: scroll;

        /deep/.ql-toolbar {
          display: none;
        }

      }
    }
  }

  // .boxShow3 {
  //   margin: 0px 0px 15px 0px;
  //   height: 39.5vh;
  //   border-radius: 8px;
  //   color: #fff;
  //   overflow: hidden;
  //   border: 1px solid #00f6ff;
  //   background: #23649387;
  //   cursor: pointer;
  //   box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
  // }

  .mouthPlan {
    padding: 20px;
    margin: 0px 7px 0px 0px;
    // color: #fff;
  }

  // .boxShow4 {
  //   margin: 0px 0px 15px 0px;
  //   height: 13.7vh;
  //   border-radius: 8px;
  //   color: #fff;
  //   overflow: hidden;
  //   border: 1px solid #00f6ff;
  //   background: #23649387;
  //   cursor: pointer;
  //   box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
  // }

  .infoBox {
    margin: 20px 20px 0px 20px;
  }

  .Maincontent {
    width: 94%;
    height: 50%;
    display: flex;
    margin-left: 3%;
    margin-top: -5px;

    .mainPlan {
      float: right;
      display: inline;
      line-height: 45px !important;
      margin-right: 10px;
    }

    .mainInfo1 {
      background-color: #35a321;
      // width: 33.3%;
      height: 100%;
    }

    .mainInfo2 {
      background-color: #448ce3;
      // width: 33.3%;
      height: 100%;
    }

    .mainInfo3 {
      background-color: #ee8373;
      // width: 33.3%;
      height: 100%;
    }
  }

  .box-content {
    display: flex;
    justify-content: space-between;

    h3 {
      margin-top: -3px;
      width: 80%;
      // overflow: hidden;
      // white-space: nowrap;
      // /*文字超出宽度则显示ellipsis省略号*/
      // text-overflow: ellipsis;
      font-size: 1.8vh
    }

    // .poin {
    //   color: #00f6ff !important;
    // }

    .newBox {
      display: flex;
      width: 9vh;

      .boxcolor1 {
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 8px;
        margin-right: 0.5vh;
        border-radius: 50px;
        background-color: #35a321;
      }

      .boxcolor2 {
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 8px;
        margin-right: 0.5vh;
        border-radius: 50px;
        background-color: #448ce3;
      }

      .boxcolor3 {
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 8px;
        margin-right: 0.5vh;
        border-radius: 50px;
        background-color: #ee8373;
      }
    }
  }

  // .boxShow5 {
  //   margin: 0px 0px 15px 0px;
  //   height: 22vh;
  //   border-radius: 8px;
  //   color: #fff;
  //   overflow: hidden;
  //   border: 1px solid #00f6ff;
  //   background: #23649387;
  //   cursor: pointer;
  //   box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
  // }
  .infoBox2 {
    margin: 20px 20px 0px 20px;
  }

  .elecount {
    margin-top: -5px;
    display: flex;
    font-size: 1.4vh;
    flex-direction: row;
    justify-content: space-between;
  }

  .elecount ol {
    // list-style-type: decimal;
    padding: 0px;
    margin: 2px 5px;
  }

  .elecount ol li {
    list-style-type: decimal;
    margin-left: 12px;

    height: 3vh;
  }

  .elecount ol li p {
    margin-top: 0px;
  }

  .pageBox {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 1;
    overflow-y: hidden;
    height: 2vh;
    line-height: 2vh;
    width: 120px;
    text-align: left;
  }
</style>
