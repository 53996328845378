<template>
  <div class="admin-project-myproject-container">

    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline">
      <el-form-item label="运行开始时间">
        <el-date-picker
        v-model="formInline.allTime"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        class="einp">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">乘梯人数</span>
        </span>
        <el-select v-model="formInline.heads" placeholder="请选择" class="einp" clearable>
          <el-option label="有人" :value="1"/>
          <el-option label="无人" :value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          <span class="lab-span">是否报警</span>
        </span>
        <el-select v-model="formInline.isSos" placeholder="请选择" class="einp" clearable>
          <el-option label="有报警" :value="0"/>
          <el-option label="无报警" :value="1"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onRefer"
          class="searchBtn"
        >查看</el-button>
      </el-form-item>
    </el-form>
      <div v-if="Total != 0">
        <el-table :data="mainList" style="width: 100%;margin-bottom: 20px;" :height="$store.state.tabHeight - 180">
          <el-table-column type="index" label="序号" min-width="55px" />
          <el-table-column label="运行开始时间" prop="startTime" min-width="160px" align="center"/>
          <el-table-column label="运行结束时间" prop="endTime" min-width="160px" align="center"/>
          <el-table-column label="运行时间(秒)" prop="runningTime" min-width="120px" align="center"/>
          <el-table-column label="运行方向" min-width="120px" align="center">
            <template #default="scope">
              <span>{{scope.row.runningDirection == 0 ? '上行' : (scope.row.runningDirection == 1 ? '下行' : '静止') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="开始楼层" prop="startFloor" min-width="100px" align="center"/>
          <el-table-column label="结束楼层" prop="endFloor" min-width="100px" align="center"/>
          <el-table-column label="运行距离(m)" prop="distance" min-width="150px" align="center"/>
          <el-table-column label="乘梯人数" prop="heads" min-width="100px" align="center"/>
          <!-- <el-table-column label="是否超载" prop="heads" min-width="100px" align="center"/> -->
          <el-table-column label="报警类型" prop="maintainUnitName" min-width="200px" align="center">
            <template #default="scope">
              <span>{{scope.row.sosArr.length > 0 ? scope.row.sosArr.join(','): '-'}}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" min-width="160px" align="center"/>
          <el-table-column label="操作" min-width="140" align="center" fixed="right">
            <template #default="scope">
              <el-button type="primary" @click="check(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="Total" :page.sync="formInline.current" :limit.sync="formInline.size"
          @pagination="dictionarPage" />
      </div>
      <div class="nullDate" v-else >
        <img
          src="@/assets/nullDate.png"
          style="width: 260px;"
        />
      </div>
      <RideQualityDetail v-if="dioDetails" :dioDetailsInfo="dioDetailsInfo" :typeDate="typeDate" @closeDetails="closeDetails"/>
  </div>
</template>

<script>
  import Pagination from '@/components/Pagination'
  import RideQualityDetail from './rideQualityDetail.vue';
  export default {
    components: {
      Pagination,
      RideQualityDetail
    },
    props: {
      elevatorId: {
        type: String,
        default: '',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        mainList: [],
        formInline: {
          current: 1,
          size: 10,
          allTime: [],
          heads: '',
          isSos: ''
        },
        Total: 0,
        dioDetails: false,
        dioDetailsInfo: null,
        typeDate: [],
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
      }
    },
    mounted() {
      // this.onRefer()
    },
    created() {
      this.$http
        .post('/api/system/web/dict/dictionary?codes=SOS_TYPE')
        .then((res) => {
          if (res.data.success) {
            const Arr = res.data.data.SOS_TYPE.filter((item)=>{
              if(this.adminInfo.userName != 'admin'){
                return item.value != '21'&&item.value != '35'
              }else{
                return item
              }
            })
            this.typeDate = Arr
          }
        })
    },
    methods: {
      // 分页
      dictionarPage(data) {
        this.formInline.current = data.page
        this.formInline.size = data.limit
        this.onRefer()
      },
      onRefer() { //电梯维修数据
        const data = {
          current: this.formInline.current,
          size: this.formInline.size,
          startTime: this.formInline.allTime && this.formInline.allTime.length > 0 ? this.formInline.allTime[0].getTime() : '',
          endTime: this.formInline.allTime && this.formInline.allTime.length > 0 ? this.formInline.allTime[1].getTime() + 24 * 3600 * 1000 - 1000 : '',
          heads: this.formInline.heads,
          isSos: this.formInline.isSos,
          eleId: this.elevatorId
        }
        this.$http.post('/api/ele/web/transport/getTransportMassList', data)
          .then((res) => {
            this.Total = res.data.data.total ?? 0
            this.mainList = []
            res.data.data.records.map(item => {
              const dataObj = item
              dataObj.sosArr = []
              item.sosList.map(dataItem => {
                const sosName = this.getType(dataItem.sosType)
                if (!dataObj.sosArr.find(sosNameItem => sosNameItem == sosName)) {
                  dataObj.sosArr.push(sosName)
                }
              })
              this.mainList.push(dataObj)
            })
            console.log(this.mainList,'this.mainList');
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          })

      },
      // 获取类型
      getType(type) {
        let obj = ''
        if (this.typeDate) {
          this.typeDate.forEach((qs) => {
            if (qs.value == type) {
              obj = qs.label
            }
          })
        }
        return obj
      },

      check(item) {
        this.tit = '详情'
        // this.dioDetailsItem = item
        this.getDetail(item)
      },
      getDetail(item) {
        this.$http.get(`/api/ele/web/transport/getTransportMassInfo?id=${item.id}`).then((res) => {
          if (res.data.success) {
            this.dioDetailsInfo = res.data.data
            this.dioDetailsInfo.sosTypeName = []
            res.data.data.sosList.map(dataItem => {
                const sosName = this.getType(dataItem.sosType)
                if (!this.dioDetailsInfo.sosTypeName.find(sosNameItem => sosNameItem == sosName)) {
                  this.dioDetailsInfo.sosTypeName.push({
                    sosName,
                    id: dataItem.sosId,
                    time: dataItem.sosTime
                  })
                }
            })
            this.dioDetails = true
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      closeDetails() {
        this.dioDetails = false
      },
    }
  }
</script>

<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
