<template>
  <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false" append-to-body
    :width="eleInfo.status !== 6 ?'1450px' : '600px'" :top="eleInfo.status !== 6 ? '8vh' : '30vh'" @close="closeDialog">
    <div v-if="eleInfo.status == 6"
      style="display: flex;justify-content: center;align-items: center;font-size: 28px;height: 20vh;">
      <div style="color: #FFF;">该电梯未入网，无法查看监控画面！</div>
    </div>
    <div v-else>
      <el-tabs style="margin-top: 10px;" v-model="activeName" lazy @tab-click="tabClick">
        <el-tab-pane label="电梯监控" name="dtjk">
          <div style="height: 70vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <eleMonitoring v-if="activeName=='dtjk'" :eleInfo="eleInfo" :elevatorId="elevatorId"
              :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="运行统计" name="yxtj" v-if="deviceCode">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <Statistics ref="Statistics" v-if="activeName=='yxtj'" :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>
        <template v-if="windowHost">
          <el-tab-pane
            v-if="(adminInfo.userName == 'admin' || adminInfo.userName == 'zxszjs' || adminInfo.userName == 'ruixing') && deviceCode"
            label="上下线记录" name="sxxjl">
            <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
              <OnoffList ref="OnoffList" v-if="activeName=='sxxjl'" :elevatorId="elevatorId" :deviceCode="deviceCode" />
            </div>
          </el-tab-pane>
        </template>
        <template v-else>
          <el-tab-pane v-if="(adminInfo.userName == 'admin' || adminInfo.userName == 'zxszjs') && deviceCode"
            label="上下线记录" name="sxxjl">
            <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
              <OnoffList ref="OnoffList" v-if="activeName=='sxxjl'" :elevatorId="elevatorId" :deviceCode="deviceCode" />
            </div>
          </el-tab-pane>
        </template>
        <el-tab-pane v-if="isShow" label="维保信息" name="wbxx">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <MaintenanceInfo ref="MaintenanceInfo" v-if="activeName=='wbxx'" :elevatorId="elevatorId"
              :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="报警信息" name="bjxx">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <SosInfo ref="SosInfo" v-if="activeName=='bjxx'" :elevatorId="elevatorId" :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="云端视频" name="splb" v-if="deviceCode">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <MonitorVideo ref="videoList" v-if="activeName=='splb'" :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="本地视频" name="scsp" v-if="deviceCode">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <UploadVideo ref="UploadVideo" v-if="activeName=='scsp'" :elevatorId="elevatorId"
              :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="运行日志" name="yxrz" v-if="deviceCode && isShow">
          <div style="height: 60vh; padding-right: 20px" class="scrollElement">
            <RunLog ref="RunLog" v-if="activeName=='yxrz'" :deviceCode="deviceCode" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="乘运质量" name="ctzl" v-if="deviceCode && adminInfo.userName == 'admin'">
          <div style="height: 60vh; padding-right: 20px" class="scrollElement">
            <RideQuality ref="RideQuality" v-if="activeName=='ctzl'" :deviceCode="deviceCode"
              :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="设备日志" name="sbrz" v-if="deviceCode && adminInfo.userName == 'admin'">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <Equipment ref="Equipment" v-if="activeName=='sbrz'" :deviceCode="deviceCode" :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="电梯检验" name="dtjy" v-if="deviceCode && isShow">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <Inspect ref="Inspect" v-if="activeName=='dtjy'" :deviceCode="deviceCode" :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="维修记录" name="wbjl" v-if="deviceCode && isShow">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <Maintain ref="Maintain" v-if="activeName=='wbjl'" :deviceCode="deviceCode" :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>

        <el-tab-pane label="运行报告" name="yxbg" v-if="deviceCode && isShow">
          <div style="height: 60vh; overflow-y: scroll; padding-right: 20px" class="scrollElement">
            <RunReport ref="RunReport" v-if="activeName=='yxbg'" :elevatorId="elevatorId" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center;">
      <el-button type="primary" v-if="eleInfo.status == 6" @click="sureClose">确定</el-button>
      <!-- <el-button size="small" @click="closeDialog">关 闭</el-button> -->
    </span>
  </el-dialog>
</template>

<script>
  import EleMonitoring from './eleMonitoring' // 电梯监控
  import Statistics from './statistics' // 运行统计
  import OnoffList from './onoffList' // 上下线
  import MaintenanceInfo from './maintenanceInfo' // 维保信息
  import SosInfo from './sosInfo' // 报警信息
  import MonitorVideo from './monitorVideo' // 电梯视频
  import UploadVideo from './uploadVideo' // 上传视频
  import RunLog from './runLog' // 运行日志
  import Equipment from './equipment' // 设备日志
  import Inspect from './Inspect.vue' //电梯检验
  import Maintain from './maintain.vue' //维修记录
  import RunReport from './runReport.vue' //运行报告
  import RideQuality from './rideQuality.vue';



  export default {
    components: {
      EleMonitoring,
      Statistics,
      OnoffList,
      MaintenanceInfo,
      SosInfo,
      MonitorVideo,
      UploadVideo,
      RunLog,
      Equipment,
      Inspect,
      Maintain,
      RunReport,
      RideQuality
    },
    data() {
      return {
        loading: true,
        dialogVisible: true,
        activeName: 'dtjk', // 默认第一屏
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        isShow: false,
        windowHost: false,

        // 初始数组
        array: [0.132, 0.134, 0.131, 0.132, 0.131, 0.134, 0.133, 0.134, 0.132, 0.139, 0.131, 0.135, 0.139, 0.137],
        // DFT 变换结果
        dftResult: [],
        // 标记是否执行了变换
        transformed: false,
      }
    },
    props: {
      eleInfo: {
        type: Object,
        default: () => ({}),
      },
      elevatorId: {
        type: String,
        default: '',
      },
      activeNameProps: {
        type: String,
        default: 'dtjk',
      },
      deviceCode: {
        type: String,
        default: '',
      },
    },

    created() {
      this.getNotice(['ELEVATOR_TYPE'])
      this.activeName = this.activeNameProps
    },
    mounted() {
      this.isShow = !(this.adminInfo.roleId == '99')
      this.windowHost = window.location.hostname != 'localhost'
      if (this.activeName === 'scsp') {
        this.$nextTick(() => {
          this.$refs['UploadVideo'].searchElevator()
        })
      }
    },
    destroyed() {},

    methods: {
      sureClose() {
        this.dialogVisible = false
        this.$emit('close')
      },
      tabClick() {
        switch (this.activeName) {
          case 'dtjk':
            break
          case 'yxtj':
            this.$nextTick(() => {
              this.$refs['Statistics'].getMainRecentDays()
              // this.$refs['Statistics'].getRunStatistical()
            })
            break
          case 'sxxjl':
            this.$nextTick(() => {
              this.$refs['OnoffList'].onRefer()
            })
            break
          case 'wbxx':
            this.$nextTick(() => {
              this.$refs['MaintenanceInfo'].onRefer()
            })
            break
          case 'bjxx':
            this.$nextTick(() => {
              this.$refs['SosInfo'].onRefer()
            })
            break
          case 'splb':
            this.$nextTick(() => {
              this.$refs['videoList'].getVideo()
            })
            break
          case 'scsp':
            this.$nextTick(() => {
              this.$refs['UploadVideo'].searchElevator()
            })
            break
          case 'yxrz':
            this.$nextTick(() => {
              this.$refs['RunLog'].getRunLogList()
            })
            break
          case 'sbrz':
            this.$nextTick(() => {
              this.$refs['Equipment'].getFileList('/')
            })
            break
          case 'dtjy':
            this.$nextTick(() => {
              this.$refs['Inspect'].getInList()
            })
            break
          case 'wxjl':
            this.$nextTick(() => {
              this.$refs['Maintain'].getMainList()
            })
            break
          case 'yxbg':
            this.$nextTick(() => {
              this.$refs['RunReport'].onRefer()
            })
            break
          case 'ctzl':
            this.$nextTick(() => {
              this.$refs['RideQuality'].onRefer()
            })
            break
          default:
            break
        }
      },

      // 获取字典
      getNotice(code) {
        this.$http
          .post(`/api/system/web/dict/dictionary?codes=${code}`)
          .then((res) => {
            if (res.data.success) {
              const {
                data: {
                  data
                },
              } = res
              this.eleType = data.ELEVATOR_TYPE
            } else {
              this.$message.error(res.data.msg)
            }
          })
      },

      closeDialog() {
        this.dialogVisible = false
        this.activeName = ''
        this.$emit('closeMonitor')
      },
    },
  }
</script>
<style lang="scss" scoped>
  /deep/.el-tabs__item,
  .el-radio,
  .el-checkbox {
    color: #fff;
  }

  /deep/.el-tabs__item {
    width: 120px;
    text-align: center;
    padding-left: 0;
  }
</style>