import Vue from 'vue'
import VueRouter from 'vue-router'

// 登录
import login from '../views/index/login.vue'
import loginWait from '../views/index/loginWait.vue'
// 大数据面板
import bigmianban from '../views/bigmianban/index.vue'
// 首页
import dashboard from '../views/index/dashboard.vue'
import home from '../views/home/home.vue'
import cwgl from '../views/cwgl/czjl/index.vue'
import sjtjPage from '../views/sjtj/index.vue'

Vue.use(VueRouter)

// 解决路由沉余
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
};

export const fixedRouter = [{
		path: '/',
		redirect: '/login'
	}, {
		path: '/login',
		name: '登录',
		component: login
	}, {
		path: '/loginWait',
		name: '登录等待',
		component: loginWait
	}, {
		path: '/bigmianban',
		name: '首页',
		component: bigmianban
	}, {
		path: '/stampPage/:id',
		name: '打印',
		component: (resolve) => require(['../views/stampPage/index.vue'], resolve)
	}, {
		path: '/YddPage/:id',
		name: '打印',
		component: (resolve) => require(['../views/YddPage/index.vue'], resolve)
	},{
		path: '/elevatorSigns/:id',
		name: '打印标志',
		component: (resolve) => require(['../views/elevatorSigns/index.vue'], resolve)
	},
	// 首页
	{
		path: '/dashboard',
		name: '首页',
		component: dashboard,
		children: [{
			path: '',
			name: '首页',
			component: home,
			meta: {
				lab: "1"
			}
		// },{
		// 	path: '/cwgl/czjl',
		// 	name: '充值记录',
		// 	component: cwgl,
		// 	meta: {
		// 		lab: "101"
		// 	}
		},{
			path: '/sjtjPage',
			name: '数据统计',
			component: sjtjPage,
			meta: {
				lab: "99"
			}
		}, {
			path: 'nullPage',
			name: '错误页',
			component: (resolve) => require(['../views/index/nullPage.vue'], resolve),
			meta: {
				lab: "2"
			}
		}]
	}, {
		path: '/newBigmianban',
		name: '监控大屏',
		component: (resolve) => require(['../views/new-bigmianban/index.vue'], resolve)
	}, {
		path: '/maintenanceBigmianban',
		name: '维保人员大屏',
		component: (resolve) => require(['../views/new-bigmianban/maintenance.vue'], resolve)
	}, {
		path: '/operate',
		name: '运营人员大屏',
		component: (resolve) => require(['../views/new-bigmianban/operate.vue'], resolve)
	}
]

export default new VueRouter({
	routes: fixedRouter

})
