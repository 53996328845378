<template>
	<div>
		<div class="header">
			<div class="dataBoxRow">
				<div class="dataBoxOne">
					<div class="dataBoxLeft">
						<el-image style="width: 90px; height: 90px" :src="require('@/assets/calendar/1.png')"
							:fit="'cover'" />
					</div>
					<div class="dataBoxRight">
						<h2>{{ headerData.sosNum }}</h2>
						<p>30日报警次数<br><span>环比上个周期： {{ headerData.sosMoM }}%</span></p>
					</div>
				</div>
			</div>
			<div class="dataBoxRow">
				<div class="dataBoxOne">
					<div class="dataBoxLeft">
						<el-image style="width: 90px; height: 90px" :src="require('@/assets/calendar/1.png')"
							:fit="'cover'" />
					</div>
					<div class="dataBoxRight">
						<h2>{{ headerData.failureNum }}</h2>
						<p>30日报故电梯数量<br><span>环比上个周期： {{ headerData.failureMoM }}%</span></p>
					</div>
				</div>
			</div>
			<div class="dataBoxRow">
				<div class="dataBoxOne">
					<div class="dataBoxLeft">
						<el-image style="width: 90px; height: 90px" :src="require('@/assets/calendar/1.png')"
							:fit="'cover'" />
					</div>
					<div class="dataBoxRight">
						<h2>{{ headerData.distance }}</h2>
						<p>运行里程预警电梯<br><span>环比上个周期： {{ headerData.distanceMoM }}%</span></p>
					</div>
				</div>
			</div>
			<div class="dataBoxRow">
				<div class="dataBoxOne">
					<div class="dataBoxLeft">
						<el-image style="width: 90px; height: 90px" :src="require('@/assets/calendar/1.png')"
							:fit="'cover'" />
					</div>
					<div class="dataBoxRight">
						<h2>{{ headerData.runningNum }}</h2>
						<p>启停次数预警电梯<br>
							<span>环比上个周期： {{ headerData.runningMoM }}%</span>
						</p>
					</div>
				</div>
			</div>
			<div class="dataBoxRow">
				<div class="dataBoxOne">
					<div class="dataBoxLeft">
						<el-image style="width: 90px; height: 90px" :src="require('@/assets/calendar/1.png')"
							:fit="'cover'" />
					</div>
					<div class="dataBoxRight">
						<h2>{{ headerData.bendNum }}</h2>
						<p>折弯次数预警电梯<br>
							<span>环比上个周期： {{ headerData.bendMoM }}%</span>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="main" style="margin-top: 15px;height: 60vh;">
			<el-row style="height: 100%;width: 100%;">
				<el-col :span="15" class="dataBoxOne" style="margin-left: 15px;height: 100%;">
					<div style="width: 100%;height: 100%">
						<h4 style="margin-bottom: 15px; margin-left: 15px">30日趋势</h4>
						<LineChart v-if="sosLineDataType" :sosLineData="sosLineData" :height="'56vh'" />
					</div>
				</el-col>
				<el-col :span="8" style="margin-left: 7px;height: 100%;">
					<div class="dataBoxOne" style="margin-right: 0px">
						<div style="width: 100%; height: 40%">
							<h4 style="margin-bottom: 0; margin-left: 15px">报警地域分布</h4>
							<SosMap v-if="sosPieDataType && sosMapData" :sosMapData="sosMapData" />
						</div>
					</div>
					<div class="dataBoxOne" style="margin-right: 0px; margin-top: 15px">
						<div style="width: 100%; height: 40%">
							<h4 style="margin-bottom: 0; margin-left: 15px">平均用时</h4>
							<PieChart v-if="sosPieDataType" :sosPieData="sosPieData" />
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination'
	import LineChart from './components/lineChart';
	import PieChart from './components/pieChart';
	import SosMap from './components/sosMap';
	export default {
		components: {
			Pagination,
			LineChart,
			PieChart,
			SosMap
		},
		data() {
			return {
				headerData: {}, //头部数据
				sosLineDataType: false, // 折线图
				sosPieDataType: false, // 折线图
				sosMapDataType: false, // 折线图
				sosMapData: [],
				sosLineData: {
					rescueNumList: [], // 救援数据
					failureNumList: [], // 故障数据
					maintainNumList: [], // 维保数据
					claimNumList: [], // 理赔数据
					sosNumList: [], // 报警数据
					dateList: [], // 日期
					dateArr: [], // 排序
				},
				sosPieData: [{
						value: 0,
						name: '维保',
						itemStyle: {
							color: '#004cff'
						}
					},
					{
						value: 0,
						name: '救援'
					},
					{
						value: 0,
						name: '理赔'
					},
					{
						value: 0,
						name: '维修'
					}
				]
			}
		},
		methods: {
			getHeader() {
				this.$http.get('/api/ele/web/sos/getTopData')
					.then((res) => {
						if (res.data.success) {
							this.headerData = res.data.data
						}
					})
			},
			getLineChart() {
				this.$http.get('/api/ele/web/sosLocation/getLineChartData')
					.then((res) => {
						this.sosLineDataType = true;
						if (res.data.success) {
							this.sosLineData = res.data.data
						}
					})
			},
			getPieChart() {
				this.$http.get('/api/ele/web/rescueLocation/getAvgTime')
					.then((res) => {
						if (res.data.success) {
							this.sosPieDataType = true;
							this.sosPieData.forEach(item => {
								if (item.name === '维保') {
									item.value = (Number(res.data.data.maintainTime) / res.data.data
										.maintainNum).toFixed(0)
								} else if (item.name === '救援') {
									item.value = (Number(res.data.data.rescueTime) / res.data.data.rescueNum)
										.toFixed(0)
								} else if (item.name === '理赔') {
									item.value = (Number(res.data.data.claimTime) / res.data.data.claimNum)
										.toFixed(0)
								} else {
									item.value = (Number(res.data.data.failureTime) / res.data.data.failureNum)
										.toFixed(0)
								}
							});
						}
					})
			},
			getCodeChart() {
				this.$http.get('/api/ele/web/sos/getMapData')
					.then((res) => {
						if (res.data.success) {
							console.log(res.data, ' res.data');
							this.sosMapDataType = true;
							this.sosMapData = res.data.data
						}
					})
			}


		},
		created() {
			this.getHeader()
			this.getLineChart()
			this.getPieChart()
			this.getCodeChart()
		}
	}
</script>

<style lang="scss" scoped>
	.header {
		display: flex;
		align-items: center;

		.dataBoxRow {
			margin: 8px 0;
			width: 20%;

			.dataBoxOne {
				.dataBoxLeft {
					width: 45%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 10px 0;
				}

				.dataBoxRight {
					width: 55%;

					h2 {
						margin-top: 10px;
						margin-bottom: 5px;
					}

					p {
						margin-top: 5px;
					}

					span {
						font-size: 12px;
					}
				}
			}
		}
	}

	// .dataBoxOne {
	// 	display: flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	margin: 0 10px;
	// 	border-radius: 8px;
	// 	color: #fff;
	// 	overflow: hidden;
	// 	border: 1px solid #00f6ff;
	// 	background: #23649387;
	// 	cursor: pointer;
	// 	box-shadow: 0 0 0 1px #2e4378, inset 0 0 10px #00f6ff;
	// }

	.nullDate {
		width: 100%;
		height: 70vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
