<template>
  <div class="content">
    <div class="page-top">
      <el-form inline :model="searchform">
        <el-form-item label="公司名称">
          <el-input placeholder="请输入公司名称" v-model="searchform.payAccount"></el-input>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input placeholder="请输入订单编号" v-model="searchform.orderNumber"></el-input>
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select placeholder="请选择支付状态" v-model="searchform.status">
            <el-option v-for="(item,index) in payList" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="充值方式">
          <el-select placeholder="请选择充值方式" v-model="searchform.type">
            <el-option v-for="(item,index) in payType" :key="index" :value="item.value" :label="item.label"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="支付单号">
          <el-input placeholder="请输入支付单号" v-model="searchform.payNumber"></el-input>
        </el-form-item>
        <el-form-item label="是否超时">
          <el-select placeholder="请选择是否超时" v-model="searchform.orderStatus">
            <el-option :key="1" :value="0" label="未超时"></el-option>
            <el-option :key="2" :value="1" label="已超时"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="转账识别码">
          <el-input placeholder="请输入转账识别码" v-model="searchform.transferIdentificationCode"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :disabled="exportType" @click="exportData()">导出</el-button>
          <el-button type="primary" @click="search">查询</el-button>
          <el-button type="warning" @click="reast">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="dictionarTotal != 0">
      <el-table :data="tableDate" ref="multipleTable" :height="$store.state.tabHeight"
        style="width: 100%;margin-bottom: 20px;">
        <el-table-column type="index" label="编号" />
        <el-table-column align="center" prop="orderNumber" label="订单编号" min-width="150px" />
        <el-table-column align="center" prop="payNumber" label="支付单号" min-width="120px" />
        <el-table-column align="center" prop="amount" label="充值金额" min-width="120px" />
        <el-table-column align="center" prop="status" label="支付状态" min-width="120px">
          <template slot-scope="scope">
            {{payList.filter(item=>item.value == scope.row.status)[0].label}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="type" label="充值方式" min-width="120px" >
          <template slot-scope="scope">
            {{payType.filter(item=>item.value == scope.row.type)[0].label}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="subsequentAmount" label="充值后余额 " min-width="120px" />
        <el-table-column align="center" prop="createTime" label="创建时间" min-width="150px" />
        <el-table-column align="center" prop="expiredTime" label="超时时间" min-width="150px" />
        <el-table-column align="center" prop="orderStatus" label="是否超时" min-width="120px">
          <template slot-scope="scope">
            {{scope.row.orderStatus == 0 ? '未超时' : (scope.row.orderStatus == 1 ? '已超时' : "未知")}}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="transferIdentificationCode" label="转账识别码" min-width="150px" />
        <el-table-column align="center" prop="payAccount" label="付款户名" min-width="150px" />
        <el-table-column align="center" prop="payCard" label="付款卡号" min-width="150px" />
        <el-table-column align="center" prop="payBank" label="付款银行" min-width="150px" />
        <el-table-column align="center" prop="collectionCard" label="收款卡号" min-width="150px" />
        <el-table-column align="center" prop="collectionBank" label="收款银行" min-width="150px" />
        <el-table-column align="center" prop="electronicReceipt" label="电子回单" min-width="200px" />
        <el-table-column align="center" prop="reason" label="不通过原因" min-width="150px" />
        <el-table-column align="center" label="操作" fixed="right" min-width="150px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" v-if="scope.row.type == 3 && (scope.row.status == 1 || scope.row.status == 0) && Number(adminInfo.roleId) == 8 " @click="examCheck(scope.row)">审核处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="dictionarTotal" :page.sync="searchform.current" :limit.sync="searchform.size"
        @pagination="noticePage" />
    </div>
    <div class="nullDate" v-else>
      <img src="@/assets/nullDate.png" style="width: 260px" />
    </div>
    <accountStock v-if="visiable" @close="back" :propFrom="fromDate" @stockClose="sureback"/>
  </div>
</template>

<script>
  import Pagination from '../../../components/Pagination.vue'
  import accountStock from './components/accountStock.vue'
  export default {
    components: {
      Pagination,
      accountStock
    },
    data() {
      return {
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        tableDate: [],
        dictionarTotal: 0,
        visiable:false,
        searchform: {
          current: 1,
          size: 10,
        },
        fromDate:{},
        payList: [{
          value: 0,
          label: '未支付'
        }, {
          value: 1,
          label: '待确认'
        }, {
          value: 2,
          label: '支付成功'
        }, {
          value: 3,
          label: '支付失败'
        }, {
          value: 4,
          label: '审核不通过'
        }, {
          value: 5,
          label: '超时关闭'
        }, {
          value: 6,
          label: '退款成功'
        }],
        payType: [{
          value: 0,
          label: '未支付'
        }, {
          value: 1,
          label: '微信'
        }, {
          value: 2,
          label: '支付宝'
        }, {
          value: 3,
          label: '对公转账'
        }],
        exportType:false,
        exportTitle:''
      }
    },
    created() {
      this.getDate()
    },
    methods: {
      //导出
      async exportData() {
        this.searchform.current = 1
        console.log(this.searchform,'666')
        this.exportType = true
        this.exportTitle = '正在导出'
        this.$http.post('/api/system/web/export/exportBaseRechargeRecordData', this.searchform, {
          responseType: 'blob'
        }).then((res) => {
          if (res.data.size > 0) {
            let blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel'
            });
            let url = URL.createObjectURL(blob);
            const link = document.createElement('a'); //创建a标签
            link.href = url;
            link.download = '充值记录.xls'; //重命名文件
            link.click();
            URL.revokeObjectURL(url);
            console.log("下载文件" + res);
          } else {
            this.$message.error('暂无数据')
          }
          this.exportType = false
          this.exportTitle = '导出'
        })
      },
      //搜索
      search() {
        this.searchform.current = 1
        this.getDate()
      },
      //翻页
      noticePage(data) {
        this.searchform.current = data.page
        this.searchform.size = data.limit
        this.getDate()
      },
      //重置
      reast() {
       this.searchform = {
         current: 1,
         size: 10,
       },
        this.getDate()
      },
      //导出
      dowMode() {

      },
      //审核
      examCheck(item){
        console.log(item,'item')
        this.fromDate = item
        this.visiable = true
      },
      //返回
      back(){
        this.visiable = false
      },
      sureback(){
        this.visiable = false
        this.getDate()
      },
      //列表
      getDate() {
        this.$http.post('/api/system/web/recharge/getRechargeRecordReviewList', this.searchform).then(res => {
          
          if (res.data.code == 200) {
            this.tableDate = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                this.$refs.multipleTable.doLayout();
              }
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .content {
    height: 85vh;
  }

  .nullDate {
    // text-align:center;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
