<template>
  <div class="admin-project-myproject-container">
    <div v-if="dictionarTotal!=0">
      <el-table
        ref="multipleTable"
        :data="tableData"
        highlight-current-row
        @sort-change="handleSort"
        :height="$store.state.tabHeight-100"
        style="width: 100%;margin-bottom: 20px;"
      >
        <el-table-column
          type="index"
          label="序号"
          width="50px"
        />
        <el-table-column
          align='center'
          prop="eleName"
          min-width="120px"
          label="电梯名称"
        />
        <el-table-column
          align='center'
          prop="maintainTypeName"
          label="计划类型"
        />
        <el-table-column
          align='center'
          prop="status"
          min-width="100px"
          label="维保状态"
        >
          <template slot-scope="scope">
            {{scope.row.status !== '' ? maintenanceStatus.find(item => item.value === scope.row.status).label : ''}}
          </template>
        </el-table-column>
        <el-table-column
          align='center'
          prop="startTime"
          min-width="100px"
          label="计划时间"
          sortable
        />
        <el-table-column
          align='center'
          prop="signTime"
          min-width="100px"
          label="签到时间"
          sortable
        />
        <el-table-column
          align='center'
          prop="finishTime"
          min-width="100px"
          label="完成时间"
          sortable
        />
        <el-table-column
          align='center'
          prop="eleCode"
          min-width="150px"
          label="电梯注册代码"
        />
        <el-table-column
          align='center'
          prop="eleCode"
          min-width="150px"
          label="AI摄像头ID"
        />
        <el-table-column
          align='center'
          prop="propertyUnitName"
          min-width="150px"
          label="物业单位"
        />
        <el-table-column
          align='center'
          prop="maintainUnitName"
          min-width="150px"
          label="维保单位"
        />
        <el-table-column
          align='center'
          min-width="120px"
          label="维保人员"
        >
					<template slot-scope="scope">
            <span :title="scope.row.maintainUserNames">
							{{ scope.row.maintainUserNames ? scope.row.maintainUserNames.split(';').slice(0,2).join() : '-' }}</span>
						</template>
					</el-table-column>
        </el-table-column>
        <el-table-column
          align='center'
          prop="eleAddress"
          min-width="150px"
          label="电梯安装位置"
        />
        <el-table-column
          align='center'
          label="操作"
          width="120px"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button
              @click="showDetails(scope.row)"
              type="primary"
              size="small"
            >查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="dictionarTotal"
        :page.sync="formInline.current"
        :limit.sync="formInline.size"
        @pagination="dictionarPage"
      />
    </div>
    <div
      class="nullDate"
      v-else
    >
      <img
        src="@/assets/nullDate.png"
        style="width: 260px;"
      />
    </div>

    <el-dialog
      v-if='dioDetails'
      :close-on-click-modal='false'
      :append-to-body="true"
      title="维保详情"
      :visible.sync="dioDetails"
      width="1200px"
      top='20vh'
    >
      <div
        style="height: 50vh;overflow-y: scroll;"
        class="scrollElement"
      >
        <dioDetails :dioData="dioDetailsItem"></dioDetails>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          size="small"
          type="primary"
          @click="dioDetails = false"
        >关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import DioDetails from '../../../dtwb/wbjl/components/dioDetails'
export default {
  components: {
    Pagination,
    DioDetails,
  },

  props: {
    elevatorId: {
      type: String,
      default: '',
    },
    deviceCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      // 维修详情
      dioDetails: false,
      dioDetailsItem: null,
      // 表格参数
      formInline: {
        current: 1,
        size: 10,
      },
      tableData: [],
      // 分页参数
      dictionarTotal: 0,
      // 维保类型
      wbType: [],
      maintenanceStatus: [
        { value: 0, label: '待处理' },
        { value: 1, label: '已签到' },
        { value: 2, label: '已维保' },
        { value: 3, label: '已驳回' },
        { value: 4, label: '已完成' },
        { value: 5, label: '超期后完成' },
      ],
    }
  },
  methods: {
    // 弹框事件
    showDetails(data) {
      this.dioDetailsItem = data
      this.dioDetails = true
    },
    // 分页
    dictionarPage(data) {
      this.formInline.current = data.page
      this.formInline.size = data.limit
      this.onRefer()
    },
    //排序
    handleSort(column,prop,order){
        if(column.prop == 'startTime'){
          this.formInline.startTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }else if(column.prop == 'signTime'){
          this.formInline.signTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }else if(column.prop == 'finishTime'){
          this.formInline.finishTimeSortSign = column.order === 'descending' ? 'DESC' : 'ASC'
        }
      this.onRefer()
    },
    // 表格方法
    onRefer(ishm) {
      this.formInline.status = 7
      this.formInline.eleId = this.elevatorId
      this.$http
        .post('/api/ele/web/maintainTask/getList', this.formInline)
        .then((res) => {
          if (res.data.success) {
            res.data.data.records.forEach((qs) => {
              qs.statusName = this.getType(qs.status, 2)
            })
            this.tableData = res.data.data.records
            this.dictionarTotal = res.data.data.total
            this.$nextTick(() => {
              if (this.dictionarTotal > 0 && this.$refs.multipleTable) {
                this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
              }
            })
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
    // 获取类型
    getType(type, sta) {
      let obj
      this.wbType.forEach((qs) => {
        if (qs.value == type) {
          obj = qs.label
        }
      })
      return obj
    },
  },
}
</script>
<style lang="scss" scoped>
.nullDate {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
