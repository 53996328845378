<template>
	<div class="dioBg">
		<div class="dioBg-om" v-if="dioData.eleSmsRescordDTOS.length > 0">
			<p>
				<span class="hpan">短信通知</span>
				<el-tooltip class="item" effect="dark" placement="top">
					<div slot="content">相同手机号仅发送一次</div>
					<i class="el-icon-warning-outline"></i>
				</el-tooltip>
			</p>
			<el-card class="infoClass" style="height:45vh">
				<div class="scrollElement" style="overflow-y: scroll;height: 40vh;">
					<div v-if="dioData.eleSmsRescordDTOS.length > 0">
						<p class="dioDataTime" v-for="(item, index) in dioData.eleSmsRescordDTOS" :key="index">
							<template v-if="item.userName">{{item.userName}}(</template>
							{{item.phoneNumber}}
							<template v-if="item.userName">)</template>
							<span style="margin-left: 20px;">{{item.createTime}}</span>
						</p>
					</div>
				</div>
				<!-- <p style="color:#ccc">已限流: 被叫号码在一定的时间内，达到次数上限，已被运营商限制被叫</p> -->
			</el-card>
		</div>

		<div class="dioBg-om" v-if="dioData.voiceRescordDTOS.length > 0">
			<p>
				<span class="hpan">电话通知</span>
				<el-tooltip class="item" effect="dark" placement="top">
					<div slot="content">相同手机号仅拨打一次</div>
					<i class="el-icon-warning-outline"></i>
				</el-tooltip>
			</p>
			<el-card class="infoClass" style="height:45vh;">
				<div class="scrollElement" style="overflow-y: scroll;height: 40vh;">

					<div v-if="dioData.voiceRescordDTOS.length > 0">
						<div v-for="(item,index) in dioData.voiceRescordDTOS" :key="index">
							<div v-if="item.isWs === 0"
								style="width: 450px;display: flex;justify-content: space-between;line-height: 2px;">
								<p :key="item.id">{{item.userName}}({{item.phoneNumber}}) ----
									<span style="font-weight: 600; font-size: 17px;"
										:style="item.code == 'OK'? 'color:#0f0' : 'color:#ff5b5b'">{{item.code == 'OK' ? '调用成功' : '已限流'}}</span>
								</p>
								<p
									v-if="oldArr.findIndex(ids => ids === 0) >= 0 && dioData.voiceRescordDTOS[oldArr.findIndex(ids => ids === 0)].isWs === 0">
									{{ dioData.voiceRescordDTOS[index].createTime}}
								</p>
							</div>
							<div v-else style="width: 450px;display: flex;justify-content: space-between;">
								<!-- <p>电话拨打时间: {{item.callTime }}</p> -->
								<p :key="item.id">文山州指挥中心({{item.phoneNumber}}) ----
									<span style="font-weight: 600; font-size: 17px;"
										:style="item.code == 'OK'? 'color:#0f0' : 'color:#ff5b5b'">{{item.code == 'OK' ? '调用成功' : '已限流'}}</span>
								</p>
								<p>电话拨打时间: {{item.callTime }}</p>
							</div>
						</div>
					</div>
					<p v-if="dioData.voiceRescordDTOS.find(item => item.code != 'OK')" style="color:#ccc">已限流:
						被叫号码在一定的时间内，达到次数上限，已被运营商限制被叫</p>
				</div>
			</el-card>
		</div>

	</div>
</template>

<script>
	export default {
		props: ['dioData'],
		data() {
			return {
				showUrl: null,
				oldArr: []
			}
		},
		computed: {
			codeStatus() {
				if (this.dioData.voiceRescordDTOS.find(item => item.code != 'OK')) {
					return false
				}
			}
		},
		mounted() {
			console.log(this.dioData, 'dioData')
			this.dioData.voiceRescordDTOS.forEach((item) => {
				this.oldArr.push(item.isWs)
			})
			this.showUrl = this.dioData.sosVideo.length > 0 ? this.dioData.sosVideo[0].videoUrl : ''
		},
		methods: {
			showVoid(url) {
				this.showUrl = url
			}
		}
	}
</script>

<style lang="scss" scoped>
	.dioDataTime {
		width: 400px;
		display: flex;
		justify-content: space-between;
	}

	/deep/.infoClass {
		// margin-top: 20px;
		margin: 20px 0px;

		.el-card__body {
			padding: 15px !important;
			font-size: 15px;
			min-height: 100px;
			// overflow-y: scroll;
		}
	}

	.dioBg {
		width: 98%;
		height: 100%;
		display: flex;

		.hpan {
			margin-right: 5px;
			font-weight: bold;
			font-size: 18px;
		}

		.dioBg-om {
			width: 48%;
			height: 50vh;
			margin-right: 2%;
		}
	}
</style>